






































































































































































































































































































































































import { Component, Prop, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import NationalityAutocomplete from "@/components/common/country/NationalityAutocomplete.vue";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import moment from "moment";
import momentUtility from "@/utility/momentUtility";
import { CrewMemberModel } from "@/api/generated";
import UserModule from "@/store/userModule";
import CrewMemberProfilePicture from "@/components/crew-member/CrewMemberProfilePicture.vue";
import { ContractType, UserModel } from '../../api/generated/api';
import { inviteUserToApp } from '@/utility/inviteUserToApp';
import Discriminator from '../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    AgencyAutocomplete,
    NationalityAutocomplete,
    JobRoleAutocomplete,
    CrewMemberProfilePicture
  }
})
export default class CrewMemberEdit extends Validation {
  @Ref() private readonly form!: VForm;
  @Prop(String) private crewMemberId!: string;

  private maxDate: moment.Moment = moment();
  private loading = false;
  private deleteLoading = false;
  private updateProfilePictureDialog = false;
  private model: CrewMemberModel = {
    countryId: "",
    agencyId: "",
    firstname: "",
    lastname: "",
    dateOfBirth: "",
    passportNumber: "",
    passportExpiry: "",
    employmentStartDate: "",
    employmentEndDate: "",
    seamansBookNumber: "",
    seamansBookIssued: "",
    seamansBookExpiry: "",
    rating: 1,
    placeOfBirth: "",
    contactNumber: "",
    alternativeContactNumber: "",
    nextOfKinName: "",
    nextOfKinContactNumber: "",
    jobRoles: [],
    discriminator: ContractType.NUMBER_1
  };
  public originalEmail = "";
  public userModel?: UserModel = undefined;
  private jobRoles: Array<string | undefined> = [];
  private nextOfKinRelationships: string[] = [
    "Mother",
    "Father",
    "Wife",
    "Brother",
    "Sister",
    "Son",
    "Daughter",
    "Other"
  ];

  private get maxAllowedDate() {
    return this.maxDate.format("yyyy-MM-DD");
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  get discriminator() {
    return Discriminator.LAND_WORKERS;
  }

  get getCrewProfilePicture() {
    return this.model.profilePictureId;
  }

  public async onResetCrewPassword() {
    if(!this.model.emailAddress) return;

    try {
      this.loading = true;

      await Api.AuthService.apiAuthSendPasswordResetPost(
        {
          emailAddress: this.model.emailAddress
        }
      );
      snackbarModule.setSnackbarMessage("Password reset successfully sent");
    } catch {
      snackbarModule.setSnackbarMessage("Password reset failed");
    } finally {
      this.loading = false;
    }
  }

  private async created() {
    await this.getCrewMember();

    if (!this.model.jobRoles) {
      return;
    }

    this.jobRoles = this.model.jobRoles
      .filter(x => x.jobRoleId)
      .map(x => x.jobRoleId);
  }

  public get isEmailChanged() {
    return this.originalEmail !== this.model.emailAddress;
  }

  private async getCrewMember() {
    const response = await Api.CrewMemberService.apiCrewmemberCrewMemberIdGet(
      this.crewMemberId
    );

    this.model = response.data;
    this.userModel = response.data.linkedUser;
    this.originalEmail = this.model.emailAddress ?? "";

    if (this.model.dateOfBirth) {
      this.model.dateOfBirth = momentUtility.pickerFormat(
        this.model.dateOfBirth
      );
    }

    if (this.model.seamansBookIssued) {
      this.model.seamansBookIssued = momentUtility.pickerFormat(
        this.model.seamansBookIssued
      );
    }

    if (this.model.seamansBookExpiry) {
      this.model.seamansBookExpiry = momentUtility.pickerFormat(
        this.model.seamansBookExpiry
      );
    }

    if (this.model.passportIssued) {
      this.model.passportIssued = momentUtility.pickerFormat(
        this.model.passportIssued
      );
    }

    if (this.model.passportExpiry) {
      this.model.passportExpiry = momentUtility.pickerFormat(
        this.model.passportExpiry
      );
    }

    if (this.model.employmentStartDate) {
      this.model.employmentStartDate = momentUtility.pickerFormat(
        this.model.employmentStartDate
      );
    }

    if (this.model.employmentEndDate) {
      this.model.employmentEndDate = momentUtility.pickerFormat(
        this.model.employmentEndDate
      );
    }
  }

  private async onUpdateCrewMember() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;

      this.model.jobRoles = [];

      this.jobRoles.forEach(x => {
        this.model.jobRoles?.push({
          crewMemberId: this.crewMemberId,
          jobRoleId: x
        });
      });

      await Api.CrewMemberService.apiCrewmemberCrewMemberIdPut(
        this.crewMemberId,
        this.model
      );

      await this.getCrewMember();

      snackbarModule.setSnackbarMessage("Saved");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to save");
    } finally {
      this.loading = false;
    }
  }

  private async onCrewMemberDelete() {
    try {
      this.deleteLoading = true;
      await Api.CrewMemberService.apiCrewmemberCrewMemberIdDelete(
        this.crewMemberId
      );
      snackbarModule.setSnackbarMessage("Land worker deleted");
      this.$router.replace({ name: "Land Workers Overview" });
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete crew member");
    } finally {
      this.deleteLoading = false;
    }
  }

  private async updateDoNotUse() {
    try {
      if (this.crewMemberId) {
        await Api.CrewMemberService.apiCrewmemberCrewMemberIdPut(
          this.crewMemberId,
          this.model
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update user to Do not use");
    } finally {
      await this.getCrewMember();
    }
  }

  private onBack() {
    this.$router.push({ name: "Land Workers Overview" });
  }
}
